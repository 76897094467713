@import '../../../variables.scss';

.landing {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 60px;
  background-color: #011327;
  height: 100vh;

  @media (min-width: 760px) {
    background-color:white;
  }
}