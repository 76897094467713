@import '../../variables.scss';

.card {
  width: 140px;
  margin: 1em .5em;
  display:inline-block;
  *display:inline;/* For IE7*/
  *zoom:1;/* For IE7*/
  vertical-align:top;




  img {
    width: 100%;
    background-color: lighten($black, 2%);
    aspect-ratio: 2/3;
    border-radius: 3%;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.10);

  }

}
