// Fonts
$balooPaaji: 'Baloo Paaji 2', cursive;
$balooTammudu: 'Baloo Tammudu 2', cursive;
$lato: 'Lato', sans-serif;
$montserrat: 'Montserrat', sans-serif;
$openSans: 'Open Sans', sans-serif;
$roboto: 'Roboto', sans-serif;

// colors
$black: #323131;
$lightGreen: #038C8C;
$darkGreen: #025959;
$yellow: #F2B705;
$orange: #D96704;
$brown: #733702;