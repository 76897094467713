@import '../../../variables.scss';

.trending {
	position: relative;
	width: 100%;
	background-color: #011327;
    padding-top: 50px;

	//   &::after {
	//     content  : "";
	//     position : absolute;
	//     z-index  : 1;
	//     bottom   : 0;
	//     left     : 0;
	//     pointer-events   : none;
	//     // background-image : linear-gradient(to bottom,
	//     //                   rgba(255,255,255, 0),
	//     //                   rgba(255,255,255, .9) 90%);
	//     background: linear-gradient(90deg, rgba(2,0,36,0) 95%, rgba(255,255,255,1) 100%);
	//     width    : 100%;
	//     height   : 260px;
	// }

	.heading {
		display: flex;
		justify-content: space-between;
		padding: 0.5em 1em;
		align-items: center;

		h2 {
			color: $lightGreen;
			font-weight: bold;
			font-size: 1.3rem;
			font-family: $lato;
			margin-top: 1em;
		}

		button {
			color: $lightGreen;
		}
	}

	.cardContainer {
		align-self: center;
		overflow-y: scroll;
		overflow-x: hidden;
		white-space: nowrap;
		background-color: #011327;
		height: 300px;
		// display: flex;
		// width: 100%;
		// flex-wrap: wrap;

		.card {
			width: 140px;
			margin: 1em 0.5em;
			display: inline-block;
			*display: inline; /* For IE7*/
			*zoom: 1; /* For IE7*/
			vertical-align: top;

			img {
				width: 100%;
				background-color: lighten($black, 2%);
				aspect-ratio: 2/3;
				border-radius: 3%;
				box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.1);
			}
		}
	}

	.skeleton {
		width: 140px;
		margin: 1em;
		display: inline-block;
		*display: inline; /* For IE7*/
		*zoom: 1; /* For IE7*/
		vertical-align: top;
		background-color: lighten($black, 20%);
		aspect-ratio: 2/3;
		filter: blur(2px);
		border-radius: 3%;
	}
}
