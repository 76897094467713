@import '../../../variables.scss';
// @import 'node_modules/react-modal-video/scss/modal-video.scss';

.showDetails {
  padding-top: 80px;
  color:  white;
  font-family: $roboto;
  display: flex;
  flex-direction: column;
  align-items: center;

  .backLink {
    padding-bottom: 20px;
    padding-left: 10px;
    align-self: flex-start;
  }
  .backdrop {
    width: 100%;
    aspect-ratio: 2/1;
    background-size: cover;
    background-position: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .poster {
      width: 25%;
      background-color: lighten($black, 2%);
      aspect-ratio: 2/3;
      margin: 1em;
    }

  }

  .titleAndFavorite {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  h2 {
    font-size: 1.5rem;
    padding: .5em;

    span {
      font-size: 1.2rem;
    }
  }

  .scoreAndTrailer {
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: .5em 0;

    .score {
      font-size: 1rem;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      width: 45%;

        p:first-child {
          text-align: center;
          margin-right: .5em;
          border: 3px solid rgb(14, 201, 14);
          border-radius: 100%;
          width: 2.5em;
          height: 2.5em;
          padding-top: .6em;
        }

    }

    .divider {
      width: 1px;
      background: lighten(#232323, 15%);
      align-self: stretch;
    }

    button {
      font-size: 1rem;
      font-family: $openSans;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: bold;
      padding-bottom: .5em;
      width: 45%;

      .playIcon {
        padding-right: .2em;
      }
    }

  }
  .classification {
    width: 100%;
    display: flex;
    flex-direction: column;

    .ratingAndLength {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      .USrating {
        p {
        color: lighten(white, 25%);
        border: 2px solid lighten(white, 25%);
        border-radius: 5px;
        padding: .2em;
        background-color: black;
        }
      }

      .dotDivider {
        font-size: 1.5rem;
        margin: 0 .5em;
      }
    }

    .genres {
      display: flex;
      justify-content: center;
      padding: .3em 0 .5em 0;
      flex-wrap: wrap;

      p {
        margin-right: .3em;
      }
    }
  }

  .overview {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 1em;

    p:first-child {
      margin-bottom: .5em;
      font-size: 1.2rem;
    }

    .creator {
      font-size: 1.1rem;
      margin: 1em 0 .2em;
    }
  }

  .Favorite {
    width: 50%;
    font-size: 1.5rem;
    padding: 0 0 .5em;
    display: flex;
    justify-content: space-evenly;
  }


}
