@import '../../../../variables.scss';

.cast {
  position: relative;
  width: 100%;
  padding: 1em;

  p {
    font-size: 1.2rem;
  }

  .castCards {
    align-self: center;
    overflow-x: scroll;
    overflow-y: hidden;
    white-space:nowrap;
  }


  .card {
    max-width: 140px;
    margin: 1em ;
    display:inline-block;
    *display:inline;/* For IE7*/
    *zoom:1;/* For IE7*/
    vertical-align:top;

    .actor {
      text-align: start;
      padding: .5em;
      height: 75px;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;

      p {
        padding: 0;
        margin: 0;
      }
    }

    .actor:last-of-type {
      font-weight: bold;
      height: 60px;
      // margin-top: .5em;
    }

    .link {
      border-radius: 3%;
      cursor: pointer;
      text-decoration: none;
    }

      p {
        font-size: 1rem;
        padding: .5em 0;
        text-align: center;
      }

      img {
        width: 100%;
        aspect-ratio: 2/3;
        border-radius: 3%;
        box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.10);

      }
  }

}
