@import '../../../../variables.scss';

.similarShows {
  position: relative;
  width: 100%;
  padding: 1em;

  p {
    font-size: 1.2rem;
  }

  .cardContainer {
    align-self: center;
    overflow-x: scroll;
    overflow-y: hidden;
    white-space:nowrap;
    // height: 250px;
    // display: flex;
    // width: 100%;
    // flex-wrap: wrap;

    }

}