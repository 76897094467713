@import '../../../variables.scss';

.search {
  color: white;
  font-family: $lato;
  height: 250px;
  width: 100%;
  background-size: cover;
  background-position: center;
  background-blend-mode: multiply;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1em 0 0;
  margin-bottom: 1.5em;

  @media (min-width: 760px) {
    flex-direction: row;
    justify-content: space-between;
    padding-right: 2em;
    margin: 0;
  }

  h1 {
    font-size: 2rem;
    padding: 0 2.5em 0 1.5em;

    @media (min-width: 425px) {
      margin-top: 1em;
    }

    @media (min-width: 760px) {
      width: 60%;
      font-size: 2.4rem;
      margin: 0;
    }


    b {
      font-weight: bold;
      font-size: 2.2rem;
    }
  }

  form{
    margin-top: 3em;
    position: relative;
    transform: translate(-25%,-50%);
    transition: all 1s;
    margin-left: auto;
    width: 50px;
    height: 50px;
    background: white;
    box-sizing: border-box;
    border-radius: 25px;
    border: 4px solid white;
    padding: 5px;
}

input{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;;
    height: 42.5px;
    line-height: 30px;
    outline: 0;
    border: 0;
    display: none;
    font-size: 1em;
    border-radius: 20px;
    padding: 0 20px;
}

.icon{
    box-sizing: border-box;
    padding: 10px;
    width: 42.5px;
    height: 42.5px;
    position: absolute;
    top: 0;
    right: 0;
    border-radius: 50%;
    color: #07051a;
    text-align: center;
    font-size: 1.2em;
    transition: all 1s;
}

form:hover{
    width: 250px;
    cursor: pointer;
}

form:hover input{
    display: block;
}

form:hover .fa{
    background: #07051a;
    color: white;
}
}