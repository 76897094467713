@import '../../../variables.scss';

.navBar {
  background: #181818;
  width: 100%;
  height: 60px;
  position: fixed;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 1000px;
	margin: 0 auto;

  @media (min-width: 900px) {
    background-color: white;
  }


  .hamburger {
    color: white;
    position: absolute;
    right: 5%;
    cursor: pointer;
    border-radius: 100%;
    padding: .3em;


    &:active {
      background-color: lighten(#181818, 5%);
        transform: scale(0.98);
        /* Scaling button to 0.98 to its original size */
        box-shadow: 3px 2px 22px 1px rgba(0, 0, 0, 0.24);
        /* Lowering the shadow */
    }
  }

  nav ul {
    @media (max-width: 900px) {
      display: flex;
      flex-direction: column;
      position: absolute;
      height: 100vh;
      width: 80vw;
      top: 60px;
      right: 0;
      padding: 1em;
      background-color: #181818;
      opacity: 0.95;
    }
  }

  nav ul li {
    display: inline-block;
    margin: 10px;
  }

  .navLink {
    color: white;
    text-decoration: none;
    font-family: $openSans;
    font-size: 1.1rem;

    @media (min-width: 900px) {
      color: $black;
      font-weight: bold;
    }

    &:hover {
      text-decoration: underline;
      text-decoration-color: $lightGreen;
      text-decoration-thickness: 4px;
      text-underline-position: under;
    }
  }

  .mobileNavigation {
    display: none;
    @media (max-width: 900px) {
      display: flex;
      align-items: center;
    }
  }

  .navigation {
    @media (max-width: 900px) {
      display: none;
    }
  }
}

