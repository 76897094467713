@import '../../../variables.scss';

.searchResults {
  width: 100%;
  background-color: white;
  padding: 1em .5em;

  .showDetails {
    text-decoration: none;
    margin-top: 1em;
    display: flex;
    height: 100%;
    height: auto;
    color: $darkGreen;
    padding: 0;
    border-radius: 5px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.10);


    .imageWrapper {
      width: 25%;

    .showImage {
      width: 100%;
      aspect-ratio: 2/3;
    }

  }

    .details {
      width: 100%;
      padding: .5em .5em .5em .5em;
      display: flex;
      flex-direction: column;
      color: $darkGreen;
      font-family: $lato;

      .nameAndAirDate {
        display: flex;
        justify-content: space-between;
        padding: 0 1em 1em 0;

        h4 {
          color: #181818;
          font-weight: bold;
          width: 50%;
        }

        .firstAired {
          font-size: 1rem;
          color: $darkGreen;
          display: flex;
          flex-direction: column;
          align-items: flex-end;
        }
      }

    }

  }
}