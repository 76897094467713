@import '../../../variables.scss';

.upNext {
  display: flex;
  flex-direction: column;
  padding-top: 100px;

  h2 {
    padding: .5em 1em;
    color: $lightGreen;
  }

  .daysShows {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: .5em;

    h3 {
      padding: .5em 1em;
      color: lightblue;
      background-color: lighten($black, 10%);
      text-align: center;
      border-radius: 20px;
      font-size: 1rem;


    }

    .showDetails {
      text-decoration: none;
      margin-top: 1em;
      padding: 0 .5em 0 0;
      display: flex;
      height: 100%;
      height: auto;
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.10);


      .imageWrapper {
        width: 25%;

      .showImage {
        width: 100%;
        aspect-ratio: 2/3;
      }

    }

      .details {
        width: 100%;
        padding: .5em 0 0 .5em;
        display: flex;
        flex-direction: column;
        justify-content: center;
        color: white;
        font-family: $lato;

        @media (min-width: 760px) {
          color: $darkGreen;
          height: 100%;
          padding: 3em 1em;
        }

        h4 {
          @media (min-width: 760px) {
            color: $darkGreen;
            font-weight: bold;
          }
        }

        .nameAndNetwork {
          display: flex;
          justify-content: space-between;

          @media (min-width: 760px) {
            padding-bottom: 2em;
          }


          .network {
            font-size: 1rem;
            color: $lightGreen;
          }
        }

        .episodeNumber {
          margin: 1em 0;
        }
      }

    }

    .noInfo {
      color: white;
      font-family: $lato;
      font-size: 1rem;
      padding: 1em .5em;
    }
    @media (min-width: 760px) {
      color: $darkGreen;
    }
  }
}

